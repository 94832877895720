<template>
  <div />
</template>

<script>
import Toastification from '@/services/Toastification'
import axiosInstance from '@/libs/axiosInstance'

export default {
  async created() {
    await this.logout()

    // เก็บค่า UserType ของผู้ใช้ก่อนจะถูกล้าง
    const userType = this.$store.getters['heroAiAuthentications/getUserType']

    this.$store.commit('heroAiAuthentications/doLogout')
    this.$ability.update(this.$store.getters['heroAiAuthentications/getPermissions'])

    if (userType === 'Internal') {
      await this.$router.push({ name: 'heroai-login' })
    } else {
      await this.$router.push({ name: 'heroai-client-login' })
    }
  },
  methods: {
    async logout() {
      try {
        const response = await axiosInstance.post('logout', null)

        const toastObject = Toastification.getContentSuccess(response.data.message)
        this.$toast(toastObject)
      } catch (_) {
        //
      }
    },
  },
}
</script>
